import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import {
  ApolloModule,
  APOLLO_NAMED_OPTIONS,
  NamedOptions,
} from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client';
import { TokenInterceptor } from '@deuna/bo-sh-helpers-ng';

import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './modules/empty-route/empty-route.component';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
  ],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          newClientName: {
            name: 'backend',
            cache: new InMemoryCache(),
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore',
              },
              query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
              },
            },
            link: httpLink.create({
              uri: environment.backend,
              extractFiles: (body) => extractFiles(body, isExtractableFile),
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              }),
            }),
          },
        };
      },
      deps: [HttpLink],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
